.panel-dimmer--open .bottom-panel {
  height: 95% !important;
}

.bottom-panel {
  width: 100%;
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  z-index: 10;
  height: 0;
  &__rounded {
    border-radius: 30px 30px 0 0 !important;
  }

  &.is-anchored-top {
    border-radius: 0 !important;
  }

  &.is-dragging {
    transition: border-radius 250ms linear;
    & > .panel-draggable-element {
      cursor: grab;
    }
  }

  &:not(.is-dragging) {
    transition: height 150ms linear, border-radius 250ms linear;
    & > .panel-draggable-element {
      cursor: unset;
    }
  }

  & > .divider {
    margin: 0 !important;
  }

  &__content {
    flex: 1;
    overflow-y: scroll !important;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.panel-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden;
  transition: background-color 250ms linear;
  background-color: rgba(0, 0, 0, 0);

  &--open {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.panel-header-container {
  padding: 15px;
}

.panel-draggable-element {
  width: 100%;
  height: 40px;
  flex-shrink: 0;

  &::after {
    content: '';
    background-color: #d9d9d9;
    width: 60px;
    height: 6px;
    transform: translate(-50%, -50%);
    position: relative;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    display: block;
  }
  &--disabled {
    &::after {
      opacity: 0.3;
    }
  }
}
