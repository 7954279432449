.ProgramBlock {
  margin-top: 20px;

  .Collapsible {
    width: 100%;
    background-color: white;
    padding: 0;
    margin-bottom: 20px;
    border-top: 8px var(--ac-color-primary) solid;

    &__trigger {
      cursor: pointer;
      display: block;
      padding: 1em 0;
    }
  }

  &__day {
    display: flex;
    justify-content: center;
    align-items: center;

    .side {
      margin: 0px;
      transform: rotate(-90deg);
      text-transform: uppercase;
      font-weight: normal;
      width: 70px;
      font-size: 0.9em !important;
      text-align: center;
    }

    h3.ui.header.date {
      flex: 1;
      text-transform: uppercase;
      margin: 0px;
      padding-left: 1em;
    }

    i.chevron {
      border: 2px solid #666;
      border-radius: 20px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;
      margin-right: 20px;
      line-height: 1;
    }
  }

  &__details {
    padding: 1em;
    display: flex;
    justify-content: center;

    .items {
      flex: 1;
      padding-right: 30px;

      .item {
        padding-bottom: 1em;
        margin-bottom: 1em;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #a8a5a5;

        &:last-child {
          border-bottom: none;
        }

        .ui.image.icon {
          padding-left: 0px;
          width: max-content;
          flex: unset;
          margin-right: 10px;
        }

        > i.icon {
          font-size: 2em;
        }

        i.icon {
          color: var(--ac-color-secondary);
        }

        .content {
          flex: 1;

          h4.ui.header.title {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 1.1em;
          }

          .time {
            font-size: 1.05em;
            color: var(--ac-color-secondary);
          }

          .subtitle {
            color: #a8a5a5;
          }

          .location {
            margin: 0.25em 0;
          }

          .description {
            margin-top: 0.5em;
            opacity: 0.9;
          }

          .image {
            width: 100%;
            border-radius: 8px;
          }

          &--image {
            flex: 260px;
            flex-grow: 0;
            margin-left: 1em;
          }
        }
      }

      > .image {
        padding-left: 30px;
        flex: 1;
      }
    }
  }

  &__cardWithSessions {
    margin-bottom: 3em;
  }
}

@media (max-width: 480px) {
  .ProgramBlock {
    &__day {
      h3.ui.header.date {
        padding-left: 1rem;
      }

      .side {
        width: 60px;
      }
    }

    &__details {
      padding: 0 1rem;

      > .image {
        display: none;
      }
    }

    .items {
      padding-right: 0px;

      .item {
        flex-direction: row;
        &.has-image {
          flex-direction: column;
        }

        .content--image {
          flex: 0;
          width: 100%;
          margin-left: 0;
        }
      }

      .image {
        margin-top: 0.5em;
      }
    }
  }
}
