@import "../components/ContactsBlock/variants/mixins";

.participants-grid {
    .ui.card.card--speaker {
        @include respond(mobile) {
            display: flex;
            flex-direction: row;
            margin: 0 0 1em 0;
            width: 100% !important;

            .card__image {
                flex-shrink: 0;

                > img.avatar {
                    margin: 8px;
                    height: 52px;
                    width: 52px;
                }
            }

            .card__content {
                text-align: left;
                align-self: center;
                padding: 12px 8px 12px 4px;
                flex: 1;

                .header {
                    margin-bottom: 0;
                }
                .description {
                    margin-top: 0;
                }
            }

        }
    }
}
