.secondary-menu {
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }

  &__logoAndPageName {
    display: flex;
    align-items: center;
    justify-content: center;

    .home {
      margin-right: 20px;
    }
  }

  &__item--profile {
    .lastName {
      margin-left: 0.3em;
    }
  }

  &__counter {
    background-color: red;
    color: white;
    width: 18px;
    height: 18px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    line-height: 18px;
    position: absolute;
    right: 10px;
    font-size: 11px;
    top: calc(50% - 9px);
  }
}

.menu--header {
  .secondary {
    flex-shrink: 0;

    .item {


      &--MessagesPopup,
      &--NotificationsPopup {
        margin-right: 4px;
        position: relative;

        .ui.image.image-icon {
          min-width: 30px;
          display: inline !important;
        }

        label {
          display: none;
        }

        span.secondary-menu__counter {
          right: 0px;
          top: 0px;
          height: 16px;
          width: 16px;
          line-height: 16px;
          font-size: 10px;
        }
      }
    }
  }
}


.item--languages {
  &-horizontal {
    span {
      &:not(:last-child):after {
        content: "/";
      }
    }

    a {
      text-transform: uppercase;
      padding: 0.5em 0.5em;
    }
  }
}

.mobile-menu__secondary {
  .secondary-menu__item--type-button {
    margin: 2px;
  }
}