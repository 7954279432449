.block--image {
  &.cms--block {
    &.align {
      &-center {
        margin: 0 auto;
      }
      &-left {
        margin-right: auto;
      }
      &-right {
        margin-left: auto;
      }
    }
  }
}
