.SquareSpeakerHeader {
  &.ui.card {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    box-shadow: none;
  }

  &__content {
    flex: 1;
    padding: 0px 20px !important;
    text-align: left;
    border: none !important;

    .speaker-card--social-network {
      margin-top: 5px;
    }
  }

  &__header {
    display: flex;

    &--left {
      display: initial;

      > img {
        border-radius: 30px;
        opacity: 1;
        box-shadow: 1px 1px 3px #00000029;
      }
    }

    &--right {
      flex-direction: column;
      letter-spacing: 0px;
      color: #00185e;
      opacity: 1;
      padding-left: 10px;

      &.is-logoEmpty {
        padding-left: 0px;
      }
    }
  }

  &__firstNameLastName {
    font-size: 24px;
  }

  &__description {
    letter-spacing: 0px;
    color: #4d5592 !important;
    opacity: 1;
    margin-bottom: 10px;
  }

  &__socialNetworks {
    margin-bottom: 4px;
  }
}

@media (max-width: 767px) {
  .SquareSpeakerHeader {
    &.ui.card {
      display: block;

      .content {
        padding: 0px !important;
      }
    }
  }
}
