.page--plan {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  
    .page--cms-container {
      flex: 1;
      display: flex;
      flex-direction: column;
  
      > .content {
        flex: 1;
  
        > .ui.container {
          height: 100%;
        }
      }
    }

    .PlanBlock,
    .leaflet-container {
        height: 100%;
    }
  
    @media only screen and (max-width: 767px) {
      .ui.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }


    // Desktop and tablet, allow scrolling and add min-height...
    @media only screen and (min-width: 768px) {
      overflow: auto;
      
      .page--cms-container {
        min-height: 100vh;
        flex-shrink: 0;
      }
    }
  }
  