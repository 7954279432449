.content.borderless {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &:after {
    content: initial !important;
  }

  .workshop {
    &--header {
      margin-top: 4px;
      display: flex;
      align-items: center;
    }
    &--is {
      &-registered,
      &-not-registered {
        background-color: var(--ac-color-primary);
        color: white;
        max-width: max-content;
        padding: 2px 8px;
        border-radius: 8px;
        font-size: 0.9em;
      }
      &-not-registered {
        display: none;
      }
    }
  }

  .description {
    .location {
      color: rgba(0, 0, 0, 0.87);
    }
    i.icon {
      color: var(--ac-color-secondary);
    }
  }
}
