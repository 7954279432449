.InstallPWA {
  &__label {
    font-size: 1.2em;
  }

  &__logo-container{
    display: flex;
    font-size: 1em;
    align-items: center;
    gap: 2em;
    margin-top: 1em;
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 3em;
  }

  &__checkbox {
    margin-top: 1em;
  }
}
