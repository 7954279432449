.registration-unavailable {
  color: #b44b4b;
  font-weight: bold;
}

.workshop--registrationBlock {
  .has-other-sessions {
    .has-other-sessions-dates {
      display: flex;
      flex-direction: column;
    }
  }
}
