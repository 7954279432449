#root > .not-available-on-desktop {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .ui.segment {
    padding: 2em;

    .image.logo {
      margin: auto;
      max-height: 80px;
      object-fit: contain;
      margin-bottom: 1em;
    }
  }
}
