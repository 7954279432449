.AgendaTab {
  flex: 1;
  height: 0px;
  overflow-y: auto;
  &__days {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__day {
    min-width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.8em;
    margin: 0 4px;
    border-radius: 4px;
    cursor: pointer;

    &:not(.is-active) {
      padding-top: 0.6em;
      padding-bottom: 0.6em;
      background-color: var(--ac-color-primary-10);
    }
    &.is-active {
      background-color: var(--ac-color-primary-40);

      .date {
        color: black;
        font-size: 1.5em;
      }
    }
    .date {
      font-size: 1.2em;
      font-weight: bold;
    }
    .month {
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  &__events {
    margin-top: 1em;
  }
  &__event {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px;
    border: 1px solid var(--ac-color-primary);
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 8px;

    .time {
      font-weight: bold;
    }

    .title a {
      color: black !important;
    }

    .actions {
      margin-top: 0.4em;
      display: flex;

      .ui.button.external-button {
        min-width: max-content;
      }

      .ui.red.button {
        background-color: #ea8484;
      }
    }
  }
}
